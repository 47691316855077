import React from 'react';

import { brandDetails } from "../data/settingsDataOptions";


export default function PageServerError() {
    return (
        <>
            <article className="vh-100 dt w-100 bg-blue">
                <div className="dtc v-mid tc white ph3 ph4-l">
                    <div className="center ph3 ph4-m ph5-l max-body-sizing">
                        <div className="flex flex-row-ns">
                            <div className="w-100 w-20-ns"></div>

                            <div className="w-100 w-60-ns">
                                <div className="pt3">
                                    <span className="mdi mdi-emoticon-cry-outline white" style={{ fontSize: "88px" }}></span>
                                </div>
                                <div className="f-headline lh-solid avenir f3 fw7 ttu white-70 pt2 pb3 bb b--white-70">¯\_(ツ)_/¯ ...Server Down... ¯\_(ツ)_/¯</div>
                                <div className="f-headline lh-solid avenir f6 fw5 yellow pt3 pb4">Please inform <a href="mailto:admin@hirebred.com" className="bold i yellow">{brandDetails.brandName}'s Administrator</a> to get it resolved.</div>
                            </div>

                            <div className="w-100 w-20-ns"></div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}