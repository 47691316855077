import React, { useState } from 'react';
import { Icon } from 'antd';

import { brandDetails } from "../data/settingsDataOptions";

import Modal from 'react-awesome-modal';


export default function PageLanding() {

    let [ showModal, setShowModal ] = useState(false);


    return (
        <>
            <article className="vh-100 dt w-100 bg-blue">
                <div className="dtc v-mid tc white ph3 ph4-l">
                    <div className="center ph3 ph4-m ph5-l max-body-sizing">
                        <div className="flex flex-row-ns">
                            <div className="w-100 w-20-ns"></div>

                            <div className="w-100 w-60-ns pb4 bb b--white-70">
                                <span className="bg-black-80 ba b--black dib pa3 w2 h2 br-100">
                                    <Icon type="message" style={{ fontSize: "25px", color: "#ffffff" }} />
                                </span>
                                <span>
                                    <h1 className="mt2 mb0 white avenir ttu fw1 f-5">{brandDetails.brandName}</h1>
                                </span>
                                <h2 className="mt0 mb0 white f6 fw4 ttu tracked">{brandDetails.brandPitch}</h2>
                            </div>

                            <div className="w-100 w-20-ns"></div>
                        </div>

                        <div className="flex flex-row-ns">
                            <div className="w-100 w-20-ns"></div>

                            <div className="w-100 w-60-ns">
                                <div className="f-headline lh-solid avenir f6 fw5 white-70 pv4">{`Drop us your email if you're keen to find out our progress!`}</div>
                                <div className="">
                                    <button 
                                        className="bg-blue ph4 pv2 mh3 ba bw2 b--white br-pill hover-bg-yellow white hover-black"
                                        style={{ cursor: "pointer" }}
                                        onClick={(event) => { setShowModal(event); }}
                                    >
                                        <span className={`f5 ttu`}>I'm Interested!</span>
                                    </button>
                                </div>
                                <Modal 
                                    visible={showModal}
                                    width="450"
                                    height="350"
                                    effect="fadeInUp"
                                    onClickAway={() => {setShowModal(false);}}
                                >
                                    <iframe 
                                        id="typeform-full" 
                                        title="Hirebred Interested List" 
                                        width="100%" 
                                        height="100%" 
                                        frameBorder="0" 
                                        src="https://roberryarts.typeform.com/to/YEbWgk"
                                    >
                                    </iframe>
                                </Modal>
                            </div>

                            <div className="w-100 w-20-ns"></div>
                        </div>
                    </div>
                </div>
            </article>
        </>
    )
}