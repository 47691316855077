import { createContext } from "react";

import { brandDetails } from "../data/settingsDataOptions";


// SETUP OVERALL CONTEXT.PROVIDER FOR "ContextAppControls"
export const ContextAppControls = createContext(null);


// SETUP REDUCER METHODS FOR "reducerJobTags"
export const initialAppControls = { 
    title: `${brandDetails.brandName.toUpperCase()} . ${brandDetails.brandPitch}`
};
export const reducerAppControls = ( state, action ) => {
    switch (action.type) {
        case "updateDocumentTitle":
            // Making a Copy of the Array State  
            return { title: `${brandDetails.brandName.toUpperCase()} . ${action.title}`};


        default:
            return state;
    }
};



// SETUP REDUCER METHODS FOR "reducerUnsplashCountries"
export const initialUnsplashCountries = { data: null };
export const reducerUnsplashCountries = ( state, action ) => {
    switch (action.type) {
        case "updateUnsplashCountries":
            // Do a simple data overwrite over existing data
            return { data: action.data };


        case "addToUnsplashCountries":
            // Do an append of new-array to the original-array list of data
            return [...state.data, ...action.data];
    
        
        default:
            return state;
    }
};



// SETUP REDUCER METHODS FOR "reducerUnsplashCovers"
export const initialUnsplashCovers = { data: [] };
export const reducerUnsplashCovers = ( state, action ) => {
    switch (action.type) {
        case "updateUnsplashCovers":
            // Do a simple data overwrite over existing data
            return { data: action.data };

        
        default:
            return state;
    }
};



// SETUP REDUCER METHODS FOR "reducerUnsplashCollections"
export const initialUnsplashCollections = { data: null };
export const reducerUnsplashCollections = ( state, action ) => {
    switch (action.type) {
        case "updateUnsplashCollections":
            // Do a simple data overwrite over existing data
            return { data: action.data };

        
        default:
            return state;
    }
};