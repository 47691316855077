

// SETUP REDUCER METHODS FOR "reducerAuth0Data"
export const initialAuth0Data = null;
export const reducerAuth0Data = ( state, action ) => {
    switch (action.type) {
        case "updateAuth0Data":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerJobsRAWData"
export const initialJobsRAWData = [];
export const reducerJobsRAWData = ( state, action ) => {
    switch (action.type) {
        case "updateJobsRAWData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerJobsData"
export const initialJobsData = [];
export const reducerJobsData = ( state, action ) => {
    switch (action.type) {
        case "updateJobsData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerCategoriesData"
export const initialCategoriesData = [];
export const reducerCategoriesData = ( state, action ) => {
    switch (action.type) {
        case "updateCategoriesData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerCompaniesData"
export const initialCompaniesData = [];
export const reducerCompaniesData = ( state, action ) => {
    switch (action.type) {
        case "updateCompaniesData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerJobAdPackagesData"
export const initialJobAdPackagesData = [];
export const reducerJobAdPackagesData = ( state, action ) => {
    switch (action.type) {
        case "updateJobAdPackagesData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerTagsData"
export const initialTagsData = [];
export const reducerTagsData = ( state, action ) => {
    switch (action.type) {
        case "updateTagsData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerLocationsData"
export const initialLocationsData = [];
export const reducerLocationsData = ( state, action ) => {
    switch (action.type) {
        case "updateLocationsData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};


// SETUP REDUCER METHODS FOR "reducerCollectionsData"
export const initialCollectionsData = [];
export const reducerCollectionsData = ( state, action ) => {
    switch (action.type) {
        case "updateCollectionsData":
            // Do a simple data overwrite over existing data
            return action.data;

        
        default:
            return state;
    }
};
