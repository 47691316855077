import React from 'react';


// React Spinners
// REF: https://www.npmjs.com/package/react-spinners
// REF: https://www.react-spinners.com/

// React-Loadable
// REF: https://github.com/jamiebuilds/react-loadable#optsrender
// REF: https://css-tricks.com/using-react-loadable-for-code-splitting-by-components-and-routes/


export default function LoadingPage({ props }) {
    
    /*
    if (props.error) {
        return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
    } else if (props.timedOut) {
        return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
    } else if (props.pastDelay) {
        return <div>Loading Page...</div>;
    } else {
        return null;
    }
    */
    
    return (
        <div></div>
    )
}