
// RETRIVAL SETUP SETTINGS FOR CONNECTION TO HIREBRED BACKEND API VIA JWT WEB TOKENS MACHINE-TO-MACHINE APPROVAL
// Asking Auth0 for tokens from my application
// REF: AUTH0 CODE REFERENCES: https://manage.auth0.com/dashboard/us/hirebred/apis/5d0c1cacd2861007309fadcb/test
// REF: CODE APPLICATION EXAMPLE REFERENCES: https://appdividend.com/2018/08/30/getting-started-with-axios-tutorial-example/
// REF: AXIOS EXAMPLES: https://www.npmjs.com/package/axios
// REF: AUTH0 CLIENT CREDENTIALS FLOW: https://auth0.com/docs/api/authentication?javascript#client-credentials-flow
export const postAuth0TokenConfig = {
    method: "POST",
    url: process.env.REACT_APP_JWT_AUTH0_URL,
    headers: { 
        "content-type": "application/x-www-form-urlencoded",
    },
    form: {
        "client_id": process.env.REACT_APP_JWT_AUTH0_CLIENT_ID,
        "client_secret": process.env.REACT_APP_JWT_AUTH0_CLIENT_SECRET,
        "audience": process.env.REACT_APP_JWT_AUTH0_API_IDENTIFIER,
        "grant_type":"client_credentials"
    },
};