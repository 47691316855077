export const brandDetails = {
    brandName: "Hirebred",
    brandCompany: "Hirebred Technologies Inc.",
    brandTagline: "Where Job Opportunities Travel",
    brandPitch: "Growing Job Opportunities For The Everyday Worker",
    brandAddress: "Singapore, SG",
    brandContactEmail: "hello@hirebred.com",
    brandAdminEmail: "admin@hirebred.com",
    brandMailerEmail: "mailer@hirebred.com",
    brandBackupEmail: "hirebred@gmail.com", // ALTERNATIVE BACKUP: backupmail@hirebred.com
    brandNoReplyName: "No-Reply",
    brandNoReplyEmail: "no-reply@hirebred.com",
};

export const appControlsDetails = {
    sectionHeaderJobs: "Jobs",
    sectionTaglineJobs: "Growing Job Opportunities For The Everyday Worker",
    
    sectionHeaderCategories: "Categories",
    sectionTaglineCategories: "Dive Into Your Specialist Area",
    sectionBannerCategories: "",

    sectionHeaderCompanies: "Companies",
    sectionTaglineCompanies: "Presenting The Markets' Best Brands",
    sectionBannerCompanies: "",

    sectionHeaderCollections: "Collections",
    sectionTaglineCollections: "Explore Your Job Search Differently",
    sectionBannerCollections: "",

    sectionHeaderTags: "Tags",
    sectionTaglineTags: "Browse These Collectives To Find Your Mark",
    sectionBannerTags: "",

    sectionHeaderLocations: "Locations",
    sectionTaglineLocations: "Go To Wherever Opportunities May Lie",
    sectionBannerLocations: "",

    sectionHeaderNewJob: "Create Opportunity",
    sectionTaglineNewJob: "Create That Next Best Opportunity For The Market",

    sectionHeaderSearch: "Search",
    sectionTaglineSearch: "",
    sectionBannerSearch: "",

    sectionHeaderAbout: "About",
    sectionTaglineAbout: "",
    sectionBannerAbout: "gcsNOsPEXfs",

    sectionHeaderFAQ: "FAQ",
    sectionTaglineFAQ: "",
    sectionBannerFAQ: "qX8vHmS46xg",

    sectionHeaderChangelog: "Changelog",
    sectionTaglineChangelog: "",
    sectionBannerChangelog: "",

    sectionHeaderTerms: "Terms",
    sectionTaglineTerms: "",
    sectionBannerTerms: "",

    sectionHeaderPrivacy: "Privacy",
    sectionTaglinePrivacy: "",
    sectionBannerPrivacy: "",

    sectionHeaderError: "#404 Error",
    sectionTaglineError: "You Look Like You're Lost",
    sectionBannerError: "1iesvNXsuZs",

    sectionHeaderSettings: "Settings"
};

export const settingsLinksList = [
    { varName: "Jobs", varLink: "/jobs", varIcon: "mdi-clipboard-text", varHeader: appControlsDetails.sectionHeaderJobs, varTagLine: appControlsDetails.sectionTaglineJobs },
    { varName: "Categories", varLink: "/categories", varIcon: "mdi-folder-open", varHeader: appControlsDetails.sectionHeaderCategories, varTagLine: appControlsDetails.sectionTaglineCategories },
    { varName: "Companies", varLink: "/companies", varIcon: "mdi-briefcase", varHeader: appControlsDetails.sectionHeaderCompanies, varTagLine: appControlsDetails.sectionTaglineCompanies },
    { varName: "Collections", varLink: "/collections", varIcon: "mdi-collage", varHeader: appControlsDetails.sectionHeaderCollections, varTagLine: appControlsDetails.sectionTaglineCollections },
    { varName: "Tags", varLink: "/tags", varIcon: "mdi-tag-multiple", varHeader: appControlsDetails.sectionHeaderTags, varTagLine: appControlsDetails.sectionTaglineTags },
    { varName: "Locations", varLink: "/locations", varIcon: "mdi-map-marker-radius", varHeader: appControlsDetails.sectionHeaderLocations, varTagLine: appControlsDetails.sectionTaglineLocations }
];

export const pagesLinksList = [
    { varName: "Search", varLink: "/", varIcon: "mdi-magnify", varHeader: appControlsDetails.sectionHeaderSearch, varTagLine: appControlsDetails.sectionTaglineSearch },
    { varName: "#404 Error", varLink: "/", varIcon: "mdi-block-helper", varHeader: appControlsDetails.sectionHeaderError, varTagLine: appControlsDetails.sectionTaglineError },
    { varName: "About", varLink: "/about", varIcon: "mdi-relative-scale", varHeader: appControlsDetails.sectionHeaderAbout, varTagLine: appControlsDetails.sectionTaglineAbout },
    { varName: "FAQ", varLink: "/faq", varIcon: "mdi-tooltip-text", varHeader: appControlsDetails.sectionHeaderFAQ, varTagLine: appControlsDetails.sectionTaglineFAQ },
    { varName: "Changelog", varLink: "/changelog", varIcon: "mdi-tooltip-text", varHeader: appControlsDetails.sectionHeaderChangelog, varTagLine: appControlsDetails.sectionTaglineChangelog },
    { varName: "Terms", varLink: "/terms", varIcon: "mdi-file-document", varHeader: appControlsDetails.sectionHeaderTerms, varTagLine: appControlsDetails.sectionTaglineTerms },
    { varName: "Privacy", varLink: "/privacy", varIcon: "mdi-face-recognition", varHeader: appControlsDetails.sectionHeaderPrivacy, varTagLine: appControlsDetails.sectionTaglinePrivacy }
];


// Custom Footer Links Arrangement
export const footerLinksList = [
    { varName: "About", varLink: "/about", varIcon: "mdi-relative-scale", varHeader: appControlsDetails.sectionHeaderAbout, varTagLine: appControlsDetails.sectionTaglineAbout },
    { varName: "FAQ", varLink: "/faq", varIcon: "mdi-tooltip-text", varHeader: appControlsDetails.sectionHeaderFAQ, varTagLine: appControlsDetails.sectionTaglineFAQ },
    { varName: "Changelog", varLink: "/changelog", varIcon: "mdi-tooltip-text", varHeader: appControlsDetails.sectionHeaderChangelog, varTagLine: appControlsDetails.sectionTaglineChangelog },
    { varName: "Terms", varLink: "/terms", varIcon: "mdi-file-document", varHeader: appControlsDetails.sectionHeaderTerms, varTagLine: appControlsDetails.sectionTaglineTerms },
    { varName: "Privacy", varLink: "/privacy", varIcon: "mdi-face-recognition", varHeader: appControlsDetails.sectionHeaderPrivacy, varTagLine: appControlsDetails.sectionTaglinePrivacy }
];


// Form Data Options or RAW DATA Options for Filter Option Controls
export const filterOptions = {
    defaultFilterOptionCategory: "ALL",
    defaultFilterOptionTimestamp: "Last 30 Days",
    filterOptionsInitialTimestamp: [
        { categoryName: "Last 7 Days" },
        { categoryName: "Today" }
    ]
};


// Form Data Options or RAW DATA Options for Form
export const formDefaultValues = {
    jobTimestampCreated: "SOMETIME",

    companyName: "YOUR COMPANY NAME",

    jobName: "THE JOB NAME",
    jobCategory: "SOME CATEGORY",
    jobLocation: "SOME PLACE",
    jobWorkType: "WORK TYPE",
    jobWorkBasis: "WORK BASIS",
    jobTags: [{ tagName: "SOME TAGS HERE" }],

    paymentPayeeName: "YOUR PAYEE NAME",
    paymentPayeeEmail: "YOUR PAYEE EMAIL"
};
export const jobWorkTypeOptions = [{
    JobWorkTypeName: "Full-Time"
}, {
    JobWorkTypeName: "Part-Time"
}, {
    JobWorkTypeName: "Contractor"
}, {
    JobWorkTypeName: "Internship"
}];
export const jobWorkBasisOptions = [{
    JobTypeLocation: "In-House",
    JobTypeLocationDesc: "Location-specific. Based locally."
}, {
    JobTypeLocation: "Remote",
    JobTypeLocationDesc: "Work from anywhere around the globe."
}];
// Declare variables for SEGEMENT HEADERS & LINKS
export const newjobFormACreateSegments = [{
    title: "A. Tell us About Your Company",
    shortname: "Part A",
    link: "steps-create-part-a"
}, {
    title: "B. Tell us About The Job Position",
    shortname: "Part B",
    link: "steps-create-part-b"
}, {
    title: "C. Help Your Job Listing Stand Out",
    shortname: "Part C",
    link: "steps-create-part-c"
}];
// Declare variables for SEGEMENT HEADERS & LINKS
export const newjobFormBPreviewSegments = [{
    title: "",
    shortname: "I. Preview",
    link: "steps-preview-part-i"
}, {
    title: "",
    shortname: "II. Consent",
    link: "steps-preview-part-ii"
}];


export const tips = {
    companyName: "Retrieve Details for Pre-Existing Companies / Be the first to submit the first entry.",
    companyDescription: "Write a brief overview description About Your Company. This will appear on your company's profile and individual job listings.",
    companyWebsite: "Eg. “https://careers.mycompany.com/”. Point it to your company's career website. This will be the primary link for any brand relations to your company.",
    companyLogo: 
        `
            <div class="lh-copy f7 fw2">
                <div class="fw3 u">Logo Upload Recommendations</div>
                <ul class="">
                    <li>Resolution of 300px x 300px @ 72dpi (min)</li>
                    <li>File size not exceeding 2MB</li>
                </ul>
            </div>
        `,
    companyBanner:  
    `
        <div class="lh-copy f7 fw2">
            <div class="fw3 u">Banner Upload Recommendations</div>
            <ul class="">
                <li>Resolution of 1500px x 300px @ 72dpi (min)</li>
                <li>File size not exceeding 2MB</li>
            </ul>
        </div>
    `,

    jobName: "Eg. “Senior Business Associate”. Describes only one specific position.",
    jobCategory: "Select One Job Category Option.",
    jobWorkType: "Select One Work Type Option.",
    jobWorkBasis: "Select Type Of Work Basis Option.",
    jobDescription: "Write a biref overview description About The Job Role.",
    jobLocation: "Eg, “Singapore (SG) / Central Singapore”. Search is Case-Sensitive.",
    jobApplyLink: "Eg. “https://careers.mycompany.com/job/apply”. Provides the specific application link to the role.",
    jobResponsibilities: "Write a list of the Job's Responsibilities. Bullet points are recommended.",
    jobMinQualification: "Write a list of the Minimal Requirements of the Job. Bullet points are recommended.",
    jobPreQualification: "Write a list of the Preferred Requirements of the Job. Bullet points are recommended.",
    jobTags: "Add more Tags to get better reach for the Job Role.",

    paymentPayeeName: "To whom should we address the Payment Invoice to.",
    paymentPayeeEmail: "We’ll send out to you, your receipt and confirmation via this payee email.",

    paymentCardNumber: "Visa / MasterCard / AMEX Preferred.", 
    paymentCardName: "Your Name on Card here.", 
    paymentCardExpiry: "Eg. 12/19", 
    paymentCardCVC: ""
};


export const validate = ( values ) => {
    
    const errors = {};
    
    if (!values.companyName) {
        errors.companyName = "Required."
    }
    if (!values.companyDescription) {
        errors.companyDescription = "Required."
    }
    if (!values.companyWebsite) {
        errors.companyWebsite = "Required."
    }
    if (!values.companyLogo) {
        errors.companyLogo = "Required."
    }
    if (!values.companyBanner) {
        errors.companyBanner = "Required."
    }


    if (!values.jobName) {
        errors.jobName = "Required."
    }
    if (!values.jobCategory) {
        errors.jobCategory = "Required. Please select one within the given options."
    }
    if (!values.jobWorkType) {
        errors.jobWorkType = "Required. Please select one within the given options."
    }
    if (!values.jobWorkBasis) {
        errors.jobWorkBasis = "Required. Please select one within the given options."
    }
    if (!values.jobLocation) {
        errors.jobLocation = "Required. Please select the Job Location Country & Region within the given options."
    }
    if (!values.jobApplyLink) {
        errors.jobApplyLink = "Required."
    }
    if (!values.jobDescription) {
        errors.jobDescription = "Required."
    }
    if (!values.jobResponsibilities) {
        errors.jobResponsibilities = "Required."
    }
    if (!values.jobMinQualification) {
        errors.jobMinQualification = "Required."
    }
    if (!values.jobTags) {
        errors.jobTags = "Required."
    }
    

    if (!values.payeeName) {
        errors.payeeName = "Required."
    }
    if (!values.paymentPayeeEmail) {
        errors.paymentPayeeEmail = "Required."
    } else if (!/\S+@\S+\.\S+/.test(values.paymentPayeeEmail)) {
        errors.paymentPayeeEmail = 'Email address is invalid.';
      }


    if (!values.paymentCardNumber) {
        errors.paymentCardNumber = "Required."
    }
    if (!values.paymentCardName) {
        errors.paymentCardName = "Required."
    }
    if (!values.paymentCardExpiry) {
        errors.paymentCardExpiry = "Required."
    }
    if (!values.paymentCardCVC) {
        errors.paymentCardCVC = "Required."
    }


    return errors;
}


export const jobAdPackageOptionTitles = {
    adPackageHighlight: "Highlight your post",
    adPackageShow: "Show your brand",
    adPackagePin: "Pin your post for 7 days",
    adPackageList: "List on 3x daily emailers"
};