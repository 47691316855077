import React, { useState, useEffect, useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';

import LoadPageComponent from "./components/___loadComponents/___loadPageComponent";

import { postAuth0TokenConfig } from "./data/settingsAuth0";

import PageLanding from "./interfaces/PageLanding";
import PageServerError from "./interfaces/PageServerError";

import { ContextAppControls, initialAppControls, reducerAppControls, initialUnsplashCountries, reducerUnsplashCountries, initialUnsplashCovers, reducerUnsplashCovers, initialUnsplashCollections, reducerUnsplashCollections } from "./components/___storeAppOptions";
import { initialAuth0Data, reducerAuth0Data, initialJobsRAWData, reducerJobsRAWData, initialJobsData, reducerJobsData, initialCategoriesData, reducerCategoriesData, initialCompaniesData, reducerCompaniesData, initialJobAdPackagesData, reducerJobAdPackagesData, initialTagsData, reducerTagsData, initialLocationsData, reducerLocationsData, initialCollectionsData, reducerCollectionsData  } from "./components/___storeDataOptions";

import { unsplash } from "./components/___objectUnsplashOptions";
import { toJson } from 'unsplash-js';
import momentDateTime from 'moment';

import { CookiesProvider } from 'react-cookie';

import './App.css';
import 'rc-tooltip/assets/bootstrap.css';

require('dotenv').config();


const App = () => {

    // USING "react-loadable" TO LOAD BY DEFAULT FUNCTION COMPONENTS, WHICH HELPS TO IMPROVE LOAD EFFICIENCIES
    // REF: https://github.com/jamiebuilds/react-loadable
    let LoadablePageNewJob = LoadPageComponent({ loader: () => import("./interfaces/PageNewJob") });

    let LoadableInterfaceSelectedJob = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedJob") });
    let LoadableInterfaceSelectedCategory = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedCategory") });
    let LoadableInterfaceSelectedCompany = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedCompany") });
    let LoadableInterfaceSelectedCollection = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedCollection") });
    let LoadableInterfaceSelectedTag = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedTag") });
    let LoadableInterfaceSelectedLocation = LoadPageComponent({ loader: () => import("./interfaces/InterfaceSelectedLocation") });

    let LoadableInterfaceViewAllJobs = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllJobs") });
    let LoadableInterfaceViewAllCategories = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllCategories") });
    let LoadableInterfaceViewAllCompanies = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllCompanies") });
    let LoadableInterfaceViewAllCollections = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllCollections") });
    let LoadableInterfaceViewAllTags = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllTags") });
    let LoadableInterfaceViewAllLocations = LoadPageComponent({ loader: () => import("./interfaces/InterfaceViewAllLocations") });
    
    let LoadablePageSearch = LoadPageComponent({ loader: () => import("./interfaces/PageSearch") });

    let LoadablePageAbout = LoadPageComponent({ loader: () => import("./interfaces/PageAbout") });
    let LoadablePageFAQ = LoadPageComponent({ loader: () => import("./interfaces/PageFAQ") });
    let LoadablePageChangelog = LoadPageComponent({ loader: () => import("./interfaces/PageChangelog") });
    let LoadablePageTerms = LoadPageComponent({ loader: () => import("./interfaces/PageTerms") });
    let LoadablePagePrivacy = LoadPageComponent({ loader: () => import("./interfaces/PagePrivacy") });
    let LoadablePageError404 = LoadPageComponent({ loader: () => import("./interfaces/PageError404") });

    let LoadableNavSettingsSideBar = LoadPageComponent({ loader: () => import("./navigation/NavSettingsSideBar") });


    // USESTATE TO CONTROL SINGLE SUB PAGES' SETTING SIDE BAR DISPLAY --- DEFAULT SET TO CLOSE
    let [ menuOpenState, setMenuOpenState ] = useState(false);
    // REDUCER STORE METHOD TO HANDLE OVERALL RETRIEVING & SAVING OF OVERALL APP CONTROL VARIABLES
    let [ storeAppControls, dispatchAppControls ] = useReducer(reducerAppControls, initialAppControls);


    // HANDLES AUTH0 TOKEN STORAGE WITHIN REDUCER-STORE + AUTH0-LOADED CHECKS FROM AUTH0 SERVER TOKEN AUTHENTICATION
    let [ auth0Token, dispatchAuth0Token ] = useReducer(reducerAuth0Data, initialAuth0Data);
    let [ isAuth0Loaded, setIsAuth0Loaded ] = useState("empty");

    let [ isServerDataLoaded, setIsServerDataLoaded ] = useState("empty");


    // DECLARING RESPECTIVE API SERVER ADDRESSES TO PULL DATA FROM THE RESPECTIVE SERVERS
    let urlAPIJobsRAW = process.env.REACT_APP_API_URL + `/api/jobs/`;
    let urlAPICategories = process.env.REACT_APP_API_URL + `/api/categories/`;
    let urlAPICompanies = process.env.REACT_APP_API_URL + `/api/companies/`;
    let urlAPIJobAdPackages = process.env.REACT_APP_API_URL + `/api/adPackages/`;
    let urlAPITags = process.env.REACT_APP_API_URL + `/api/tags/`;
    let urlAPILocations = process.env.REACT_APP_API_URL + `/api/locations/`;
    let urlAPICollections = process.env.REACT_APP_API_URL + `/api/collections/`;

    let urlAPIRequestUnsplashCountriesA = process.env.REACT_APP_API_UNSPLASH_APP_COUNTRIES_A_COLLECTION_ID;
    let urlAPIRequestUnsplashCountriesB = process.env.REACT_APP_API_UNSPLASH_APP_COUNTRIES_B_COLLECTION_ID;
    let urlAPIRequestUnsplashCountriesC = process.env.REACT_APP_API_UNSPLASH_APP_COUNTRIES_C_COLLECTION_ID;
    let urlAPIRequestUnsplashCovers = process.env.REACT_APP_API_UNSPLASH_APP_COVERS_COLLECTION_ID;
    let urlAPIRequestUnsplashCollections = process.env.REACT_APP_API_UNSPLASH_APP_COLLECTIONS_COLLECTION_ID;

    
    let [ storeUnsplashCountries, dispatchUnsplashCountries ] = useReducer(reducerUnsplashCountries, initialUnsplashCountries);
    let [ storeUnsplashCovers, dispatchUnsplashCovers ] = useReducer(reducerUnsplashCovers, initialUnsplashCovers);
    let [ storeUnsplashCollections, dispatchUnsplashCollections ] = useReducer(reducerUnsplashCollections, initialUnsplashCollections);


    // DECLARING VARIOUS USEREDUCERS STORAGES FOR STORING DATA ACROSS THE APP, TO REDUCE FURTHER RE-REQUESTS FROM SERVER.
    // DECLARING VARIOUS USESTATES LOADED & ERROR CHECKS TO ENABLE SEGMENTS OF WEBSITES TO DO PRE-DATA-LOADED-CHECKS PRIOR TO RENDERS. 
    let [ jobsRAWData, dispatchJobsRAWData ] = useReducer(reducerJobsRAWData, initialJobsRAWData);
    let [ jobsData, dispatchJobsData ] = useReducer(reducerJobsData, initialJobsData);
    let [ categoriesData, dispatchCategoriesData ] = useReducer(reducerCategoriesData, initialCategoriesData);
    let [ companiesData, dispatchCompaniesData ] = useReducer(reducerCompaniesData, initialCompaniesData);
    let [ jobAdPackagesData, dispatchJobAdPackagesData ] = useReducer(reducerJobAdPackagesData, initialJobAdPackagesData);
    let [ tagsData, dispatchTagsData ] = useReducer(reducerTagsData, initialTagsData);
    let [ locationsData, dispatchLocationsData ] = useReducer(reducerLocationsData, initialLocationsData);
    let [ collectionsData, dispatchCollectionsData ] = useReducer(reducerCollectionsData, initialCollectionsData);    

    let [ isJobsRAWLoaded, setIsJobsRAWLoaded ] = useState("empty");
    let [ isJobsLoaded, setIsJobsLoaded ] = useState("empty");
    let [ isCategoriesLoaded, setIsCategoriesLoaded ] = useState("empty");
    let [ isCompaniesLoaded, setIsCompaniesLoaded ] = useState("empty");
    let [ isJobAdPackagesLoaded, setIsJobAdPackagesLoaded ] = useState("empty");
    let [ isTagsLoaded, setIsTagsLoaded ] = useState("empty");
    let [ isLocationsLoaded, setIsLocationsLoaded ] = useState("empty");
    let [ isCollectionsLoaded, setIsCollectionsLoaded ] = useState("empty");



    // HANDLES FINAL PUSH-UPDATE TO WEBSITE DOCUMENT TITLE NAME ON EACH PAGE-CHANGE-CLICK
    useEffect(() => {
        // UPDATE AT THE END OF EACH RENDER
        document.title = `${storeAppControls.title}`;
    });

    // CUSTOM USEEFFECT TO SPECIFICALLY PULL ALL DATA FROM RESPECTIVE SERVERS & STORE INTO REDUCER STORES FORE FURTHER PROCESSING & RENDERING
    useEffect(() => {

        // ABSTRACTS AUTHENTICATED AUTH0 TOKEN & APPLY IT TO HEADER CONFIG FOR DATA REQUESTS TO HIREBRED API SERVER
        let getBackendConfig = {
            headers: { 
                "Content-Type": "application/json",
                Authorization: process.env.REACT_APP_JWT_AUTH0_BEARER_HEADER_PREFIX + " " + auth0Token,
            },
        };


        // MAKE REQUEST TO AUTH0 TO RETRIVE AUTHENTICATED TOKEN FOR HIREBRED SERVER API ACCESS
        async function retriveAuth0Token() {
            setIsAuth0Loaded("loading");

            let request = require("request");
            request(postAuth0TokenConfig, function (error, response, body) {
                if (error) {
                    //console.log("😱 getAuth0Token Error: " + error);
                    setIsAuth0Loaded("error");
                    //throw new Error(error);   // SWITCHED OFF SO THAT "SERVER DOWN" DISPLAY ERROR CAN BE DISPLAYED
                } 

                if (response) {
                    //console.log("👉 getAuth0Token Reply Response: ", response);
                }
                if (body) {
                    let processBody = JSON.parse(body);
                    //console.log("👉 getAuth0Token Reply Body: ", processBody);
                    // STORES TOKEN FOR CONTINUED AUTH0 USAGE FOR WEBAPP 
                    dispatchAuth0Token({ type: "updateAuth0Data", data: processBody.access_token });
                    setIsAuth0Loaded("loaded");
                }
            });
        }


        // PULL DATA FROM HIREBRED BACKEND API SERVER & PUSH INTO REDUCER STORE
        async function updateJobsData() {
            try {
                setIsJobsRAWLoaded("loading");
                setIsJobsLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPIJobsRAW, getBackendConfig)
                        .then(response => {
                            // JOBS DATA MASS DATA DUMP IF PULL FROM MAIN WITHOUT FILTER FROM SERVER
                            dispatchJobsRAWData({ type: "updateJobsRAWData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsJobsRAWLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsJobsRAWLoaded("loaded");
                            }
                            
                            
                            // DO A PRE-FILTER FOR ALL DATA TO BE ONLY 30-DAYS AGO AS SIPULATED FOR EACH JOB POSTING
                            // ALSO FILTER OUT ONLY JOBS THAT ARE "job.jobPostDisplay === true", WHERE POSTS ARE VALID FOR DISPLAY PERIOD
                            let thirtyDaysAgo = momentDateTime().subtract(30, "days");
                            let jobsDataThirtyDaysAgo = response.data.filter(job => (job.jobPostDisplay === true && momentDateTime(job.jobTimestampCreated) >= thirtyDaysAgo));
                            dispatchJobsData({ type: "updateJobsData", data: jobsDataThirtyDaysAgo });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsJobsLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsJobsLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsJobsRAWLoaded("error");
                setIsJobsLoaded("error");
                //console.log("😱 updateJobsData Error: " + err);
            }
        }
        async function updateCategoriesData() {
            try {
                setIsCategoriesLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPICategories, getBackendConfig)
                        .then(response => {
                            dispatchCategoriesData({ type: "updateCategoriesData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsCategoriesLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsCategoriesLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsCategoriesLoaded("error");
                //console.log("😱 updateCategoriesData Error: " + err);
            }
        }
        async function updateCompaniesData() {
            try {
                setIsCompaniesLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPICompanies, getBackendConfig)
                        .then(response => {
                            dispatchCompaniesData({ type: "updateCompaniesData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsCompaniesLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsCompaniesLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsCompaniesLoaded("error");
                //console.log("😱 updateCompaniesData Error: " + err);
            }
        }
        async function updateJobAdPackagesData() {
            try {
                setIsJobAdPackagesLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPIJobAdPackages, getBackendConfig)
                        .then(response => {
                            dispatchJobAdPackagesData({ type: "updateJobAdPackagesData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsJobAdPackagesLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsJobAdPackagesLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsJobAdPackagesLoaded("error");
                //console.log("😱 updateJobAdPackagesData Error: " + err);
            }
        }
        async function updateTagsData() {
            try {
                setIsTagsLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPITags, getBackendConfig)
                        .then(response => {
                            dispatchTagsData({ type: "updateTagsData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsTagsLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsTagsLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsTagsLoaded("error");
                //console.log("😱 updateTagsData Error: " + err);
            }
        }
        async function updateLocationsData() {
            try {
                setIsLocationsLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPILocations, getBackendConfig)
                        .then(response => {
                            dispatchLocationsData({ type: "updateLocationsData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsLocationsLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsLocationsLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsLocationsLoaded("error");
                //console.log("😱 updateLocationsData Error: " + err);
            }
        }
        async function updateCollectionsData() {
            
            try {
                setIsCollectionsLoaded("loading");
                Promise.all([
                    await axios
                        .get(urlAPICollections, getBackendConfig)
                        .then(response => {
                            dispatchCollectionsData({ type: "updateCollectionsData", data: response.data });
                            if (response.data.length === 0) {
                                // IF RESPONSE RETURNS EMPTY ARRAY, NOTIFY "EMPTY"
                                setIsCollectionsLoaded("empty");
                            }
                            else {
                                // IF RESPONSE RETURNS MORE THAN EMPTY, NOTIFY "LOADED"
                                setIsCollectionsLoaded("loaded");
                            }
                        })
                ]);
            }
            catch (err) {
                setIsCollectionsLoaded("error");
                //console.log("😱 updateCollectionsData Error: " + err);
            }
        }



        // PULL DATA FROM UNSPLASH API & PUSH INTO REDUCER STORE --- MAXIMUM LIMIT IS ACTUALLY ONLY 30 IMAGES PER PAGE
        async function getUnsplashCountriesAData() {
            unsplash.collections.getCollectionPhotos(urlAPIRequestUnsplashCountriesA, 1, 30, "Popular")
                .then(toJson)
                .then(jsonData => {
                    //console.log("jsonData---getUnsplashCountriesAData", jsonData);
                    dispatchUnsplashCountries({ type: "updateUnsplashCountries", data: jsonData });

                    getUnsplashCountriesBData();
                });
        }
        async function getUnsplashCountriesBData() {
            unsplash.collections.getCollectionPhotos(urlAPIRequestUnsplashCountriesB, 1, 30, "Popular")
                .then(toJson)
                .then(jsonData => {
                    //console.log("jsonData---getUnsplashCountriesBData", jsonData);
                    dispatchUnsplashCountries({ type: "addToUnsplashCountries", data: jsonData });
                });
        }


        async function getUnsplashCollectionsData() {
            unsplash.collections.getCollectionPhotos(urlAPIRequestUnsplashCollections, 1, 30, "Popular")
                .then(toJson)
                .then(jsonData => {
                    //console.log("jsonData---getUnsplashCollectionsData", jsonData);
                    dispatchUnsplashCollections({ type: "updateUnsplashCollections", data: jsonData });
                });
        }

        
        
        // EXECUTE REQUEST FOR AUTH0 TOKEN RETRIVAL IF EMPTY
        if (isAuth0Loaded === "empty" && auth0Token === null && isServerDataLoaded === "empty") {
            setIsServerDataLoaded("loading");
            retriveAuth0Token();
        }

        // MAKE HIREBRED BACKEND API REQUESTS WHEN AUTH0 TOKEN IS RETRIVED
        if (isAuth0Loaded === "loaded" && auth0Token !== null && isServerDataLoaded === "loading") {
            // CALL EACH RESPECTIVE ASYNC FUNCTION FROM API SERVERS
            updateJobsData();
            updateCategoriesData();
            updateCompaniesData();
            updateJobAdPackagesData();
            updateTagsData();
            updateLocationsData();
            updateCollectionsData();

            getUnsplashCountriesAData();
            
            getUnsplashCollectionsData();
        }


    }, [
        isAuth0Loaded, auth0Token, isServerDataLoaded,
        urlAPIJobsRAW, urlAPICategories, urlAPICompanies, urlAPIJobAdPackages, urlAPITags, urlAPILocations, urlAPICollections, urlAPIRequestUnsplashCountriesA, urlAPIRequestUnsplashCountriesB, urlAPIRequestUnsplashCountriesC, urlAPIRequestUnsplashCovers, urlAPIRequestUnsplashCollections
    ]);

    
    return (
        <ContextAppControls.Provider 
            // HANDLES ALL ROUTING SERVICES WITHIN THE APP    
            value={{ 
                // LOAD REDUCER VALUES THAT WILL NOT CHANGE UPON RE-RENDERS INTO "ContextAppControls.Provider"
                storeAppControls, dispatchAppControls,

                isAuth0Loaded, setIsAuth0Loaded, auth0Token, dispatchAuth0Token,
                isServerDataLoaded, setIsServerDataLoaded,

                isJobsRAWLoaded, setIsJobsRAWLoaded, jobsRAWData, dispatchJobsRAWData,
                isJobsLoaded, setIsJobsLoaded, jobsData, dispatchJobsData,
                isCategoriesLoaded, setIsCategoriesLoaded, categoriesData, dispatchCategoriesData,
                isCompaniesLoaded, setIsCompaniesLoaded, companiesData, dispatchCompaniesData,
                isJobAdPackagesLoaded, setIsJobAdPackagesLoaded, jobAdPackagesData, dispatchJobAdPackagesData,
                isTagsLoaded, setIsTagsLoaded, tagsData, dispatchTagsData,
                isLocationsLoaded, setIsLocationsLoaded, locationsData, dispatchLocationsData,
                isCollectionsLoaded, setIsCollectionsLoaded, collectionsData, dispatchCollectionsData,

                storeUnsplashCountries, dispatchUnsplashCountries,
                storeUnsplashCovers, dispatchUnsplashCovers,
                storeUnsplashCollections, dispatchUnsplashCollections,

                // LOAD TOGGLE CONTROLS FOR SETTINGS NAV SIDE BAR FOR SINGLE PAGES FORMAT
                isMenuOpen: menuOpenState,
                toggleOpenMenu: () => setMenuOpenState(!menuOpenState),
                stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen)
            }}
        >
            <CookiesProvider>
                <Router>
                    {
                        // WHILE LOADING DATA FROM SERVER..
                        // DO NOTHING AT THE MOMENT --- NO LOADING ANIMATION SETUP
                        ((isAuth0Loaded === "loading" || isJobsLoaded === "loading" || isCategoriesLoaded === "loading" || isCompaniesLoaded === "loading" || isTagsLoaded === "loading" || isLocationsLoaded === "loading" || isCollectionsLoaded === "loading") && storeUnsplashCountries !== null && storeUnsplashCollections !== null) && (
                            <section>
                                
                            </section>
                        )
                    }
                    {
                        // WHILE ERROR LOADING DATA FROM SERVER..
                        ((isAuth0Loaded === "error" || isJobsLoaded === "error" || isCategoriesLoaded === "error" || isCompaniesLoaded === "error" || isTagsLoaded === "error" || isLocationsLoaded === "error" || isCollectionsLoaded === "error") && storeUnsplashCountries !== null && storeUnsplashCollections !== null) && (
                            <section>
                                {
                                    // ROUTING ONLY WHEN SERVER-CONNECTION IS NOT-AUTHORIZED / SERVER IS DOWN
                                    // LOADS MUCH FASTER AS NO-DATE-FROMSERVER IS REQUIRED
                                    // WILL HAVE DUPLICATE ENTRIES FOR NON-WEBAPP-LOADING-PAGES EG. /LANDING 
                                }
                                <Switch>
                                    <Route path="/" exact render={() => (
                                        <Redirect to="/landing"/>
                                    )} />
                                    <Route path="/landing" component={PageLanding} />

                                    <Route path="/:error" component={PageServerError} />
                                </Switch>
                            </section>
                        )
                    }


                    {
                        // POST-LOADED DATA FROM SERVER --- PERFORM ALL CRITICAL PRE-LOAD DATA CHECKS PRIOR TO VERY FIRST LOAD-SCREEN-RENDER
                        (isAuth0Loaded === "loaded" && isJobsLoaded === "loaded" && isCategoriesLoaded === "loaded" && isCompaniesLoaded === "loaded" && isTagsLoaded === "loaded" && isLocationsLoaded === "loaded" && isCollectionsLoaded === "loaded" && storeUnsplashCountries !== null && storeUnsplashCollections !== null) && (
                            <section id="outer-container" className={`h-100 w-100 ${(menuOpenState === false) ? "relative-position" : "fixed-position"}`} >
                                {
                                    (isCategoriesLoaded === "loaded") && (
                                        <LoadableNavSettingsSideBar setMenuOpenState={setMenuOpenState} />
                                    )
                                }
                                
                                
                                <section id="bodyComponent" className="dt-l h-100 w-100 border-box pa0">
                                    <main id="page-wrap" className={`${(menuOpenState === false) ? "" : "h-100 ba b--blue shadow-5"}`} style={{ overflow: "auto" }}>
                                    
                                        <Switch>
                                            <Route path="/" exact render={() => (
                                                <Redirect to="/landing"/>
                                            )} />
                                            <Route path="/landing" component={PageLanding} />

                                            <Route path="/jobs/new" component={LoadablePageNewJob} />
                                            <Route path="/jobs/job" component={LoadableInterfaceSelectedJob} />
                                            <Route path="/jobs" exact component={LoadableInterfaceViewAllJobs} />
                                            
                                            <Route path="/categories/category" component={LoadableInterfaceSelectedCategory} />
                                            <Route path="/categories" component={LoadableInterfaceViewAllCategories} />
                                            
                                            <Route path="/companies/company" component={LoadableInterfaceSelectedCompany} />
                                            <Route path="/companies" component={LoadableInterfaceViewAllCompanies} />

                                            <Route path="/collections/collection" component={LoadableInterfaceSelectedCollection} />
                                            <Route path="/collections" component={LoadableInterfaceViewAllCollections} />

                                            <Route path="/tags/tag" component={LoadableInterfaceSelectedTag} />
                                            <Route path="/tags" component={LoadableInterfaceViewAllTags} />

                                            <Route path="/locations/country" component={LoadableInterfaceSelectedLocation} />
                                            <Route path="/locations" component={LoadableInterfaceViewAllLocations} />

                                            <Route path="/search" component={LoadablePageSearch} />
                                            
                                            <Route path="/about" component={LoadablePageAbout} />
                                            <Route path="/faq" component={LoadablePageFAQ} />
                                            <Route path="/changelog" component={LoadablePageChangelog} />
                                            <Route path="/terms" component={LoadablePageTerms} />
                                            <Route path="/privacy" component={LoadablePagePrivacy} />
                                            <Route path="/:error" component={LoadablePageError404} />
                                        </Switch>
                                    </main>
                                </section>
                            </section>
                        )
                    }
                </Router>
            </CookiesProvider>
        </ContextAppControls.Provider>
    );
}


export default App;