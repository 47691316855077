import Loadable from 'react-loadable';
import LoadingPage from "./LoadingPage";


// react-loadable
// REF: https://github.com/jamiebuilds/react-loadable
export default function LoadPageComponent( options ) {
    return Loadable(Object.assign({
        loading: LoadingPage,
        delay: 200,
        timeout: 10000,
    }, options));
};