import Unsplash from 'unsplash-js';


// REF: https://github.com/unsplash/unsplash-js
export const unsplash = new Unsplash({
    applicationId: process.env.REACT_APP_API_UNSPLASH_APP_ACCESS_KEY, // APP_ACCESS_KEY
    secret: process.env.REACT_APP_API_UNSPLASH_APP_SECRET // APP_SECRET
});


// CURRENTLY NOT BEING USED --- PRIMARILY FOR ACCESS_TOKEN RETRIEVAL FROM UNSPLASH API
export const postUnsplashTokenConfig = {
    method: "POST",
    url: process.env.REACT_APP_API_UNSPLASH_OAUTH_TOKEN_URL,
    headers: { 
        "content-type": "application/x-www-form-urlencoded",
    },
    form: {
        "client_id": process.env.REACT_APP_API_UNSPLASH_APP_ACCESS_KEY,
        "client_secret": process.env.REACT_APP_API_UNSPLASH_APP_SECRET,
        "redirect_uri": process.env.EACT_APP_API_UNSPLASH_APP_REDIRECT_URI,
        "code": process.env.REACT_APP_API_UNSPLASH_APP_CODE,
        "grant_type":"authorization_code"
    },
};